<template>
  <div class="goods" id="goods" name="goods">
    <MyMenu :menuname="'index'"></MyMenu>
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-else>分类</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 面包屑END -->

    <!-- 分类标签 -->
    <div class="nav">
      <div class="nav-wrap">
        <div class="product-left">
          <div v-for="(item, index) in leftNavList" :key="index" :class="primaryClassifyCode == item.primaryClassifyCode ? 'left-item active' : 'left-item'" @click="handleClickNav(item.primaryClassifyCode, index)">
            <span>{{ item.primaryClassifyName }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="product-right">
          <div class="product-nav" v-for="(item, index) in categoryList" :key="item.twoClassCode">
            <div class="title">{{ item.secondaryClassifyName }}</div>
            <div class="item">
              <el-radio-group v-model="reqParams['params' + (index + 1)]">
                <el-radio border size="medium" label="99999" @click.native.prevent="handleClickCategory('99999', index + 1)">全部</el-radio>
                <el-radio border size="medium" v-for="attr in item.tertiaryPropertyList" :key="attr.propertyId" :label="attr.propertyId" @click.native="handleClickCategory(attr.propertyId, index + 1)">{{ attr.tertiaryClassifyName }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="product-nav">
            <div class="title">使用场景</div>
            <div class="item">
              <el-radio-group v-model="recommendLabel">
                <el-radio label="99999" border size="medium" @click.native.prevent="clickSence('99999')">全部</el-radio>
                <el-radio v-for="item in homeCategoryList" size="medium" border :key="item.labelName" :label="item.labelName" @click.native.prevent="clickSence(item.labelName)">{{ item.labelName }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分类标签END -->

    <!-- 主要内容区 -->
    <div class="main">
      <div class="list">
        <MyList :list="product" v-if="product.length > 0"></MyList>
        <div v-else class="none-product">
          <svg t="1683795562570" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5839" width="200" height="200">
            <path d="M64 409l227.038-152.906A24 24 0 0 1 304.444 252h417.194a24 24 0 0 1 13.492 4.151L960 409v339c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V409z" fill="#9F9F9F" fill-opacity=".5" p-id="5840"></path>
            <path d="M64 409h283.136c13.255 0 24 10.745 24 24v44.68c0 13.254 10.745 24 24 24h233.136c13.255 0 24-10.746 24-24V433c0-13.255 10.745-24 24-24H960v355a8 8 0 0 1-8 8H72a8 8 0 0 1-8-8V409z" fill="#FFFFFF" fill-opacity=".4" p-id="5841"></path>
          </svg>
          <p>
            抱歉没有找到相关的商品，请看看其他的商品
            <a href="/">返回首页</a>
          </p>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="total" @current-change="currentChange"></el-pagination>
      </div>
      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
export default {
  name: 'goods',
  data() {
    return {
      leftNavList: [],
      categoryList: [], //分类列表
      homeCategoryList: [], //首页全部分类
      product: [], // 商品列表
      total: 0, // 商品总量
      pageSize: 15, // 每页显示的商品数量
      currentPage: 1, //当前页码
      search: '', // 搜索条件
      goodsTimer: null,
      searchTimer: null,
      reqParams: {},
      recommendLabel: '99999', // 使用场景绑定值
      primaryClassifyCode: '' // 左侧一级分类列表
    }
  },
  async created() {
    // 获取分类列表
    await this.getCategory()
    this.getscene()
    let { search, recommendLabel } = this.$route.query
    if (!search && !recommendLabel) {
      this.getGoodsData()
    } else if (recommendLabel) {
      this.clickSence(recommendLabel)
    } else {
      this.search = search
    }
  },
  activated() {
    this.backtop()
    if (this.$route.query.search != undefined) {
      this.search = this.$route.query.search
    }
  },
  watch: {
    // 监听搜索条件，响应相应的商品
    search(val) {
      if (val != '') {
        this.getProductBySearch(val)
      }
    },
    // 监听路由变化，更新路由传递了搜索条件
    $route(to, from) {
      let { recommendLabel, search } = to.query
      if (search) {
        this.search = search
      }
      if (to.path == '/goods' && from.path == '/') {
        if (recommendLabel) {
          this.clickSence(recommendLabel)
        }
      } else if (to.path == '/') {
        this.currentPage = 1
        for (const key in this.reqParams) {
          this.reqParams[key] = '99999'
        }
        this.primaryClassifyCode = this.leftNavList[0].primaryClassifyCode
        this.recommendLabel = '99999'
        this.search = '' // 搜索条件
        this.getGoodsData()
      }
    }
  },
  methods: {
    // 点击左侧分类
    handleClickNav(primaryClassifyCode, index) {
      this.primaryClassifyCode = primaryClassifyCode
      this.categoryList = this.leftNavList[index].secondaryPropertyList
      this.categoryList.forEach((item, index) => {
        this.reqParams['params' + (index + 1)] = '99999'
      })
      this.currentPage = 1
      this.getGoodsData()
    },
    // 点击右侧分类
    handleClickCategory(propertyId, index) {
      this.reqParams = {
        ...this.reqParams,
        ['params' + index]: propertyId
      }
      this.currentPage = 1
      this.getGoodsData()
    },
    clickSence(recommendLabel) {
      if (this.recommendLabel != recommendLabel) {
        this.recommendLabel = recommendLabel
        this.getGoodsData()
      }
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function() {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        const speed = Math.floor(-top / 5)
        document.documentElement.scrollTop = document.body.scrollTop = top + speed

        if (top === 0) {
          clearInterval(timer)
        }
      }, 20)
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage
      if (this.search != '') {
        this.getProductBySearch()
      } else {
        this.getGoodsData()
      }
      this.backtop()
    },
    // 向后端请求分类列表数据
    async getCategory() {
      let { recommendLabel } = this.$route.query
      if (recommendLabel) {
        this.$axios
          .get('/zuwu-api/official/index?applicationType=3')
          .then(({ data }) => {
            let list = data.data.apiCommodityLabelListVo.commodityLabelList
            this.homeCategoryList = list
            this.clickSence(recommendLabel)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      await this.$axios
        .get('/zuwu-api/official/commodityPropertyList', {})
        .then(({ data }) => {
          this.leftNavList = data.data.primaryPropertyList
          this.primaryClassifyCode = this.leftNavList[0].primaryClassifyCode
          this.categoryList = this.leftNavList[0].secondaryPropertyList
          this.categoryList.forEach((item, index) => {
            this.reqParams['params' + (index + 1)] = '99999'
          })
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 拿使用场景数据
    getscene() {
      this.$axios
        .get('/zuwu-api/official/index?applicationType=3')
        .then(({ data }) => {
          let list = data.data.apiCommodityLabelListVo.commodityLabelList
          this.homeCategoryList = list
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 向后端请求全部商品或分类商品数据
    getGoodsData() {
      if (this.goodsTimer) {
        clearTimeout(this.goodsTimer)
      }
      this.goodsTimer = setTimeout(() => {
        let tertiaryClassifyCodeList = Object.values(this.reqParams).filter((item) => item !== '99999')
        let reqData = {
          primaryClassifyCode: this.primaryClassifyCode,
          query: {
            current: this.currentPage,
            size: this.pageSize
          },
          tertiaryClassifyCodeList,
          recommendLabel: this.recommendLabel == '99999' ? '' : this.recommendLabel
        }
        // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
        this.$axios
          .post('/zuwu-api/official/commodityClassPropertyList', reqData)
          .then(({ data }) => {
            let list = data.data.records
            this.total = data.data.total
            this.product = list
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      })
    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.currentPage = 1
        this.total = 0
        this.$axios
          .get('/zuwu-api/official/page', {
            params: {
              title: this.search,
              current: this.currentPage,
              size: this.pageSize
            }
          })
          .then(({ data }) => {
            let list = data.data.records
            this.total = data.data.total
            this.product = list
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      })
    }
  }
}
</script>

<style scoped>
@import '../../assets/css/index.css';
.el-radio--medium.is-bordered {
  margin-right: 10px;
}
.goods .nav-wrap {
  margin: 0 auto;
  max-width: 1225px;
  display: flex;
  padding: 12px 0 12px;
}
.goods .nav-wrap .product-left {
  padding: 20px 0 20px;
  margin-right: 14px;
  background: #fff;
  width: 234px;
}
.goods .nav-wrap .product-left .left-item {
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  padding: 4px 14px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.goods .nav-wrap .product-left .left-item i {
  font-size: 18px;
}
.goods .nav-wrap .product-left .left-item:hover {
  background: #409eff;
  color: #fff;
}
.goods .nav-wrap .product-left .active {
  background: #409eff;
  color: #fff;
}
.goods .nav-wrap .product-right {
  width: calc(100% - 248px);
  background: #fff;
  padding-top: 20px;
}
/* .goods .nav-wrap .product-nav {
  display: flex;
  flex-direction: row;
} */
.goods .nav-wrap .product-nav .title {
  width: 80px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
}
.goods .nav-wrap .product-nav .item {
  width: calc(100% - 80px);
  display: inline-block;
}
.goods .nav-wrap .product-nav .el-radio {
  margin-bottom: 12px;
  margin-left: 0px;
}
</style>
